import { ElementResetNcssTree, mergeNcss, Ncss, Wheel } from '@wheelroom/core'
import { styledSystem } from '@wheelroom/styled-system'

type ElementStylesKey = keyof ElementResetNcssTree

interface GetCssProps {
  wheel: Wheel
  style: Ncss
  ncss: Ncss
  is?: ElementStylesKey
}

export const getCss = (props: GetCssProps) => {
  const ncssToMerge = []
  if (props.is) {
    ncssToMerge.push(props.wheel.elementNcss[props.is])
  }
  ncssToMerge.push(props.style)
  ncssToMerge.push({ ncss: props.ncss })
  return styledSystem(
    props.wheel.wrSystemConfig,
    props.wheel.wrSystemTheme,
    mergeNcss(ncssToMerge)
  )
}
