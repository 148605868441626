/**
 * Component variation
 *
 * Component type: tsnTopicSection
 * Variation: logos
 *
 */

import React from 'react'
import {
  getTopicInfo,
  TopicMedia,
  TopicOptions,
  TopicSectionModel,
} from '@wheelroom/wheel-topic'
import { Container, getSectionWheel, Wrapper } from '@wheelroom/core'

export const TsnTopicSectionLogosVar = (props: TopicSectionModel) => {
  if (!props.topics || !Array.isArray(props.topics)) {
    return null
  }
  const sectionWheel = getSectionWheel({
    themeId: props.activeThemeId,
    wheelId: 'tsnTopicSection',
    sectionWheels: props.sectionWheels,
    variation: 'logos',
  })

  if (!sectionWheel || !props.topics) {
    return null
  }

  const { data, wheel } = sectionWheel
  const topicOptions: TopicOptions = {
    hideIcon: true,
    hideHeading: true,
    hideAbstract: true,
    hideAction: true,
  }
  return (
    <Wrapper wheel={{ ...wheel, style: wheel.style.wrapper }}>
      <Container wheel={{ ...wheel, style: wheel.style.container }}>
        {props.topics.map((topic, index) => (
          <TopicMedia
            key={index}
            data={data}
            topic={topic}
            topicInfo={getTopicInfo(topic)}
            topicOptions={topicOptions}
            wheel={{ ...wheel, style: wheel.style.topic }}
          />
        ))}
      </Container>
    </Wrapper>
  )
}
