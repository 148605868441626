/**
 * Component variation
 *
 * Component type: tsnTopicSection
 * Variation: carousel
 *
 */

import React, { useRef, useState, useEffect } from 'react'
import {
  getTopicOptions,
  Topic,
  TopicModel,
  TopicSectionModel,
} from '@wheelroom/wheel-topic'
import { getSectionWheel, MultiParser, Wrapper } from '@wheelroom/core'
import { getCss } from '../../lib/get-css'
import { Dots } from './dots'

export const TsnTopicSectionCarouselVar = (props: TopicSectionModel) => {
  const containerRef = useRef<any>({})
  const [activeSlide, setActiveSlide] = useState(0)
  const slideIndex = useRef(activeSlide)
  const timeoutHandle = useRef<any>()
  const TIMEOUT = 3000

  useEffect(() => {
    timeoutHandle.current = setInterval(nextSlide, TIMEOUT)
    return () => {
      clearInterval(timeoutHandle.current)
    }
  }, [])

  if (!props.topics || !Array.isArray(props.topics)) {
    return null
  }
  const slideCount = props.topics.length

  const sectionWheel = getSectionWheel({
    themeId: props.activeThemeId,
    wheelId: 'tsnTopicSection',
    sectionWheels: props.sectionWheels,
    variation: 'carousel',
  })

  if (!sectionWheel) {
    return null
  }
  const { data, wheel } = sectionWheel

  const wrapperWheel = {
    ...wheel,
    style: wheel.style.wrapper,
  }

  const containerCss = getCss({
    is: 'container',
    wheel,
    style: wheel.style.container,
    ncss: {},
  })

  const topicWheel = {
    ...wheel,
    style: wheel.style.topic,
  }

  const handleOnScroll = () => {
    const position =
      containerRef.current.scrollLeft / containerRef.current.offsetWidth
    if (position === Math.round(position)) {
      setActiveSlide(position)
    }
  }
  const handleClick = (newSlideIndex: number) => {
    clearInterval(timeoutHandle.current)
    timeoutHandle.current = setInterval(nextSlide, TIMEOUT)
    slideTo(newSlideIndex)
  }

  const slideTo = (newSlideIndex: number) => {
    containerRef.current.scrollTo({
      left: newSlideIndex * containerRef.current.offsetWidth,
      behavior: 'smooth',
    })
    setActiveSlide(newSlideIndex)
    slideIndex.current = newSlideIndex
  }

  const nextSlide = () => {
    slideIndex.current++
    if (slideIndex.current >= slideCount) {
      slideIndex.current = 0
    }
    slideTo(slideIndex.current)
  }

  return (
    <Wrapper wheel={wrapperWheel}>
      <div css={containerCss} ref={containerRef} onScroll={handleOnScroll}>
        {props.topics.map((topic: TopicModel, index: number) => (
          <Topic
            data={data}
            key={index}
            topic={topic}
            topicOptions={getTopicOptions({
              optionStrings: props.topicOptions,
              locale: props.locale,
            })}
            useAbstractParser={MultiParser}
            useHeadingElement="h3"
            wheel={topicWheel}
          />
        ))}
      </div>
      <Dots
        activeDot={activeSlide}
        numberOfDots={slideCount}
        onDotClick={(index) => handleClick(index)}
        wheel={wheel}
      />
    </Wrapper>
  )
}
