import React from 'react'
import {
  Any,
  AnyElementProps,
  Container,
  deepMerge,
  Wheel,
  Wrapper,
} from '@wheelroom/core'

interface DotProps extends AnyElementProps {
  activeDot: number
  numberOfDots: number
  onDotClick: (index: number) => any
  wheel: Wheel
}

export const Dots = (props: DotProps) => {
  const wrapperWheel = { ...props.wheel, style: props.wheel.style.wrapper }
  const containerWheel = {
    ...props.wheel,
    style: deepMerge([
      props.wheel.style.container,
      { ncss: { justifyContent: 'center' } },
    ]),
  }
  return (
    <Wrapper wheel={wrapperWheel}>
      <Container wheel={containerWheel}>
        {[...Array(props.numberOfDots)].map((_, index) => (
          <Any
            is="span"
            key={index}
            onClick={() => props.onDotClick(index)}
            wheel={props.wheel}
            ncss={{
              cursor: 'pointer',
              h: '16px',
              w: '16px',
              mx: 1,
              backgroundColor:
                index === props.activeDot ? 'magenta' : 'magentaLight',
              borderRadius: '50%',
              display: 'inline-block',
              transition: 'background-color 0.5s ease',
              ':hover': {
                backgroundColor: 'magentaShade',
              },
            }}
          />
        ))}
      </Container>
    </Wrapper>
  )
}
