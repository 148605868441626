/**
 * Component
 *
 * Component type: tsnTopicSection
 *
 */

import React from 'react'
import { getVariation } from '@wheelroom/core'
import { TopicSectionModel } from '@wheelroom/wheel-topic'
import { TsnTopicSectionCarouselVar } from './tsn-topic-section-carousel-var'
import { TsnTopicSectionLogosVar } from './tsn-topic-section-logos-var'

const componentList = {
  carousel: TsnTopicSectionCarouselVar,
  logos: TsnTopicSectionLogosVar,
}

export const TsnTopicSection = (props: TopicSectionModel) => {
  const Variation = getVariation(props, componentList)

  return <Variation {...props} />
}
